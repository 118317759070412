<template>
  <div>
    <vs-popup class="sm:popup-w-70" title="Edit STC" :active="isActive" v-on:update:active="emitModalIsActive">
      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full.5">
            <label class="ml-1 text-xs">No. Kontrak *</label>
            <div @click="modalKontrak.active = true">
              <vx-input-group>
                <vs-input placeholder="Pilih Kontrak" :value="data.no_kontrak" readonly/>
                <template slot="append">
                  <div class="append-text btn-addon">
                    <vs-button type="filled" icon-pack="feather" icon="icon-search"/>
                  </div>
                </template>
              </vx-input-group>
            </div>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. Unit</label>
            <vs-input class="w-full" v-model="data.no_unit" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Nama Customer</label>
            <vs-input class="w-full" v-model="data.nama_customer" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. STC *</label>
            <flat-pickr class="w-full" v-model="data.tgl_stc"></flat-pickr>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. AJB</label>
            <vs-input class="w-full" v-model="data.no_ajb" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. AJB</label>
            <vs-input class="w-full" v-model="data.tgl_ajb" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">No. Garansi *</label>
            <vs-input class="w-full" v-model="data.no_garansi"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Masa Garansi (hari) *</label>
            <vs-input class="w-full" type="number" v-model="data.masa_garansi" :value="data.masa_garansi"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. Habis Garansi *</label>
            <flat-pickr class="w-full" v-model="data.tgl_habis_garansi"></flat-pickr>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Nama Penerima *</label>
            <vs-input class="w-full" v-model="data.nama_penerima"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Notaris *</label>
            <vs-input class="w-full" v-model="data.notaris"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Upload Foto * <span class="text-xs" v-if="isCompressingFoto">(compressing...)</span></label>
            <vs-input class="w-full" type="file" accept="image/*" multiple v-model="data.fotoTemp" @change="onSelectedPhotos"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <label class="ml-1 text-xs">Upload Files</label>
            <vs-input class="w-full" type="file" accept=".jpeg,.jpg,.png,.pdf" multiple v-model="data.filesTemp" @change="onSelectedFiles"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal kontrak-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Kontrak"
                :active.sync="modalKontrak.active">
        <Kontrak :selectable="true" :externalFilter="filterKontrak" @selected="onSelectedModalKontrak"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import SerahTerimaCustomerRepository from '@/repositories/marketing/serah-terima-customer-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import Kontrak from '@/views/pages/marketing/kontrak/Kontrak'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import { compressImage, convertToFormData } from '@/utilities/common/global-methods'
import moment from 'moment'

export default {
  name: 'StcEdit',
  props: ['isActive', 'item'],
  components: {
    ValidationErrors,
    flatPickr,
    Kontrak
  },
  computed: {
    filterKontrak () {
      return { status_unit: 'AJB', id_stc: 'EMPTY' }
    }
  },
  watch: {
    isActive (active) {
      if (active) {
        this.data = _.cloneDeep(this.item)

        this.data.foto = []
        this.data.files = []
      }
    }
  },
  data () {
    return {
      isCompressingFoto: false,
      isLoading: false,
      errors: null,
      modalKontrak: {
        active: false
      },
      data: {
        tgl_stc: moment().format('YYYY-MM-DD'),
        np_ajb: null,
        tgl_ajb: null,
        foto: [],
        files: []
      }
    }
  },
  methods: {
    onSelectedModalKontrak (item) {
      this.data.id_kontrak = item.id
      this.data.no_kontrak = item.no_kontrak
      this.data.id_properti_unit = item.id_properti_unit
      this.data.no_unit = item.no_unit
      this.data.nama_customer = item.nama_customer
      this.modalKontrak.active = false
    },

    async onSelectedPhotos (event) {
      const files = event.target.files
      const items = []
      this.isCompressingFoto = true
      for (const file of files) {
        const compressed = await compressImage(file)
        items.push(compressed)
      }
      this.isCompressingFoto = false
      this.data.foto = items
    },

    onSelectedFiles (event) {
      const files = event.target.files
      const items = []
      for (const file of files) {
        items.push(file)
      }
      this.data.files = items
    },

    save () {
      this.errors = null
      this.isLoading = true

      const params = convertToFormData({
        id: this.data.id,
        id_kontrak: this.data.id_kontrak,
        no_stc: this.data.no_stc,
        tgl_stc: this.data.tgl_stc,
        no_ajb: this.data.no_ajb,
        tgl_ajb: this.data.tgl_ajb,
        no_garansi: this.data.no_garansi,
        masa_garansi: this.data.masa_garansi,
        tgl_habis_garansi: this.data.tgl_habis_garansi,
        nama_penerima: this.data.nama_penerima,
        notaris: this.data.notaris,
        foto: this.data.foto,
        dokumen: this.data.files
      })
      SerahTerimaCustomerRepository.update(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            const errors = error.response.data.errors
            if (errors.approver) {
              this.notifyErrorUsingDialog(errors)
            } else {
              this.errors = errors
            }
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
